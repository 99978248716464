import {
	CMS_IMAGE_WIDTH,
	CMSUtil,
	useSanityImageAttributes,
} from '@wearemojo/ui-components';
import { ReactNode, useCallback, useMemo } from 'react';

import useTransformActionToLinkProvider from '../hooks/cms/useTransformActionToLinkProvider';
import useStreamingFeatureFlag from '../hooks/useStreamingFeatureFlag';
import { logger } from '../utils/logging';
import { useTherapist } from './TherapistProvider';

const _CMSGlobalContextProvider = ({
	children,
	transformActionToActionLink,
	streamingFeatureEnabled,
}: {
	children: ReactNode;
	transformActionToActionLink: CMSUtil.CMSGlobalContextType['transformActionToActionLink'];
	streamingFeatureEnabled?: boolean;
}) => {
	const imgAttr = useSanityImageAttributes(CMS_IMAGE_WIDTH);
	const captureError = logger.captureError;
	const { therapistImage, therapistName } = useTherapist();
	const context = useMemo<CMSUtil.CMSGlobalContextType>(
		() => ({
			imgAttr,
			transformActionToActionLink,
			captureError,
			therapistImage,
			therapistName,
			streamingFeatureEnabled,
		}),
		[
			imgAttr,
			captureError,
			transformActionToActionLink,
			therapistImage,
			therapistName,
			streamingFeatureEnabled,
		],
	);
	return (
		<CMSUtil.CMSGlobalContext.Provider value={context}>
			{children}
		</CMSUtil.CMSGlobalContext.Provider>
	);
};

const CMSGlobalContextProvider = ({ children }: { children: ReactNode }) => {
	const transformActionToActionLink = useTransformActionToLinkProvider();
	const { streamingFeatureEnabled } = useStreamingFeatureFlag();

	return (
		<_CMSGlobalContextProvider
			transformActionToActionLink={transformActionToActionLink}
			streamingFeatureEnabled={streamingFeatureEnabled}
		>
			{children}
		</_CMSGlobalContextProvider>
	);
};

/**
 * Upper-level version of CMSGlobalContext that sits above ModalManager context
 * and provides access of `imgAttr` to it.
 * `transformActionToActionLink` can't be used inside Modals, so we throw an
 * error if that is ever attempted.
 */
export const CMSGlobalContextUIProvider = ({
	children,
}: {
	children: ReactNode;
}) => {
	const transformActionToActionLink = useCallback(() => {
		throw new Error(`Undefined transformActionToActionLink`);
	}, []);

	// Default to true for UI provider since feature hooks might not be available
	const streamingFeatureEnabled = true;

	return (
		<_CMSGlobalContextProvider
			transformActionToActionLink={transformActionToActionLink}
			streamingFeatureEnabled={streamingFeatureEnabled}
		>
			{children}
		</_CMSGlobalContextProvider>
	);
};

export default CMSGlobalContextProvider;
