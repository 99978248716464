import { Document } from '@wearemojo/sanity-schema/schema/_types';

import { logger } from './logging';

// roughly equivalent to `sanityref` in the Go codebase

export const resolveSanityDocuments = (
	documents: Document[],
	{ ignoreMissingDocuments = false } = {},
) => {
	const clonedOriginalDoc: Document[] = JSON.parse(JSON.stringify(documents));

	const documentsMap = convertSanityDocumentToMap(clonedOriginalDoc);
	const missingDocuments = new Set<string>();

	recursivelyResolveReferences(
		clonedOriginalDoc,
		documentsMap,
		missingDocuments,
	);

	// Log only 10% of the time
	if (
		!ignoreMissingDocuments &&
		missingDocuments.size > 0 &&
		Math.random() < 0.1
	) {
		logger.captureWarning('Missing document references', {
			missingDocuments: Array.from(missingDocuments),
		});
	}

	return documentsMap;
};

const recursivelyResolveReferences = (
	doc: Document | Document[],
	mappedDoc: Map<string, Document>,
	missingSet: Set<string>,
) => {
	if (typeof doc !== 'object' || doc === null) return;

	for (let key in doc) {
		// @ts-ignore
		// `key` here is either the key of the doc if it is an object or the index of the array
		// value is a document - object if the original doc is an array
		// or a primitive or object if the original doc is an object
		const value = doc[key];

		if (typeof value !== 'object' || value === null) continue;

		const ref = value._ref;

		if (typeof ref !== 'string') {
			recursivelyResolveReferences(value, mappedDoc, missingSet);
			continue;
		}

		const docObj = mappedDoc.get(ref);

		if (docObj) {
			// @ts-ignore
			doc[key] = docObj;
			continue;
		}

		if (
			!ignoreMissingRefs.some((prefix) => ref.startsWith(prefix)) &&
			// don't complain about weak refs, like Media Tags in the CMS
			!value?._weak
		) {
			missingSet.add(ref);
		}
	}
};

export const convertSanityDocumentToMap = (documents: Document[]) => {
	return new Map(documents.map((doc) => [doc._id, doc]));
};

const ignoreMissingRefs = ['image-'];
