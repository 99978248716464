import { Service, ServiceParams } from '@wearemojo/api-client';

import Sentry from '../../services/sentry';
import { LogsConfig, LogType } from './types';

type AnalyticsEvent =
	ServiceParams[Service.analyticsevent]['recordEvents']['events'][number];

class Logger {
	private enabled: LogsConfig | undefined;

	private getEnabled = (logType: LogType) => {
		return !!this.enabled?.[logType];
	};

	setEnabledConfig = (enabled: LogsConfig) => {
		this.enabled = enabled;
	};

	setEnabled = (logType: LogType, enabled: boolean) => {
		if (!this.enabled) return;
		this.enabled[logType] = enabled;
	};

	logAnalyticsEvent = (event: AnalyticsEvent) => {
		if (this.getEnabled('analytics')) {
			console.groupCollapsed(
				`🕵️ Track event '${event.type}' - ${event.timestamp}`,
			);
			console.log(`Data:`, event);
			console.groupEnd();
		}
	};

	logInfo = (
		message: string,
		data?: Record<string, unknown>,
		category?: Sentry.Breadcrumb['category'],
	) => {
		if (this.getEnabled('info')) {
			console.groupCollapsed(`👀 Info '${message}'`);
			console.log(`Data:`, data);
			console.groupEnd();
		}
		Sentry.addBreadcrumb({
			message,
			data,
			category,
		});
	};

	captureInfo = (message: string, data?: Record<string, unknown>) => {
		if (this.getEnabled('info')) {
			console.groupCollapsed(`👀 Info '${message}'`);
			console.log(`Data:`, data);
			console.groupEnd();
		}
		Sentry.captureMessage(message, {
			level: 'info',
			extra: data,
		});
	};

	captureWarning = (message: string, data?: Record<string, unknown>) => {
		if (this.getEnabled('warning')) {
			console.groupCollapsed(`⚠️ Warning '${message}'`);
			console.log(`Data:`, data);
			console.groupEnd();
		}
		Sentry.captureMessage(message, {
			level: 'warning',
			extra: data,
		});
	};

	captureError = (message: string, data?: Record<string, unknown>) => {
		if (this.getEnabled('error')) {
			console.groupCollapsed(`❗️ Error '${message}'`);
			console.log(`Data:`, data);
			console.groupEnd();
		}
		Sentry.captureMessage(message, {
			level: 'error',
			extra: data,
		});
	};

	captureException = (error: any) => {
		if (this.getEnabled('error')) {
			console.groupCollapsed(`❗️ Error '${error.message}'`);
			console.log(`Error:`, error);
			console.groupEnd();
		}
		Sentry.captureException(error);
	};
}

const logger = new Logger();

export { logger, LogType };
