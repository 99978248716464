enum ScreenKey {
	Account = 'Account',
	AccountLegal = 'AccountLegal',
	AccountManageBilling = 'AccountManageBilling',
	AccountPreferences = 'AccountPreferences',
	AccountSettings = 'AccountSettings',
	ActivityComplete = 'ActivityComplete',
	ActivityHelp = 'ActivityHelp',
	ActivityLearning = 'ActivityLearning',
	ActivityStaging = 'ActivityStaging',
	ActivityVariant = 'ActivityVariant',
	AppNudge = 'AppNudge',
	AppReview = 'AppReview',
	Auth = 'Auth',
	AuthMobileIntro = 'AuthMobileIntro',
	AuthRecoverAccount = 'AuthRecoverAccount',
	AuthRedirectApple = 'AuthRedirectApple',
	AuthRedirectMagicLink = 'AuthRedirectMagicLink',
	AuthRegisterPreface = 'AuthRegisterPreface',
	CancelationDiscountApplied = 'CancelationDiscountApplied',
	CancelationDiscountOffer = 'CancelationDiscountOffer',
	CancelationInvestorFriendlyQuestion = 'CancelationInvestorFriendlyQuestion',
	CancelationNoDiscount = 'CancelationNoDiscount',
	CancelationQuestion = 'CancelationQuestion',
	CancelationSubQuestion = 'CancelationSubQuestion',
	CancelationSuccess = 'CancelationSuccess',
	CelebrateCheckout = 'CelebrateCheckout',
	Challenge = 'Challenge',
	ChallengeSelector = 'ChallengeSelector',
	ChangePollResponses = 'ChangePollResponses',
	CheckIn = 'CheckIn',
	Checkout = 'Checkout', // redirects
	CheckoutOneTimeOffer = 'CheckoutOneTimeOffer',
	CheckoutPayment = 'CheckoutPayment',
	CheckoutPreface = 'CheckoutPreface',
	CheckoutRedirect = 'CheckoutRedirect',
	CheckoutSubscribe = 'CheckoutSubscribe',
	CheckoutSubscriptionPlans = 'CheckoutSubscriptionPlans',
	CheckoutSuccess = 'CheckoutSuccess',
	CheckoutTrialOptions = 'CheckoutTrialOptions',
	Community = 'Community',
	CommunityCreateTopic = 'CommunityCreateTopic',
	CommunityExpertNote = 'CommunityExpertNote',
	CommunityTopic = 'CommunityTopic',
	Consent = 'Consent',
	ContentValidation = 'ContentValidation',
	DevCMSContent = 'DevCMSContent',
	DevFlow = 'DevFlow',
	DevWhoops = 'DevWhoops',
	DiscourseSso = 'DiscourseSso',
	EarnedCoins = 'EarnedCoins',
	EfficacyQuestions = 'EfficacyQuestions',
	EroticStories = 'EroticStories',
	Explore = 'Explore', // @TODO: rename to Resources (if name sticks)
	Goal = 'Goal',
	GoNative = 'GoNative',
	GoWeb = 'GoWeb',
	Home = 'Home',
	HomeRedirect = 'HomeRedirect',
	ItoMemories = 'ItoMemories',
	LibraryCategory = 'LibraryCategory',
	LibraryGroup = 'LibraryGroup',
	MediaItem = 'MediaItem',
	MemberSpace = 'MemberSpace',
	NotFound = 'NotFound',
	PostCancelationQuestion = 'PostCancelationQuestion',
	PostCancelationSuccess = 'PostCancelationSuccess',
	Refer = 'Refer',
	ReferralPartner = 'ReferralPartner',
	Routine = 'Routine',
	SeeAllActivities = 'SeeAllActivities',
	SoundEpisode = 'SoundEpisode',
	SoundSeries = 'SoundSeries',
	SQScorePillar = 'SQScorePillar',
	SQScorePillarList = 'SQScorePillarList',
	StreakGained = 'StreakGained',
	StreaksCalendar = 'StreaksCalendar',
	StreaksCommitment = 'StreaksCommitment',
	SwapActivityQuestions = 'SwapActivityQuestions',
	Welcome = 'Welcome',
}

export default ScreenKey;
