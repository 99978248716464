import {
	RouteProp,
	useFocusEffect,
	useNavigation,
	useRoute,
} from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import Cher from '@wearemojo/cher';
import {
	AbstractOverpanelSheet,
	abstractOverpanelSheetPatterns,
	ChallengeProgressBottomSheet,
	LoadingIndicator,
} from '@wearemojo/ui-components';
import { useCallback, useMemo, useRef, useState } from 'react';

import { useWhoopsHandler } from '../../errors/WhoopsProvider';
import { useChallengeProgressBottomSheet } from '../../hooks/challenges/useChallengeProgressBottomSheet';
import { useActivities } from '../../hooks/content/useActivities';
import useActivityCoinEarningStatus from '../../hooks/useActivityCoinEarningStatus';
import useActivityPillar from '../../hooks/useActivityPillar';
import useAppDispatch from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import useCoinImage from '../../hooks/useCoinImage';
import useNavigationRedirect from '../../hooks/useNavigationRedirect';
import useResolveActivityVariants from '../../hooks/useResolveActivityVariants';
import NavigatorKey from '../../navigation/NavigatorKey';
import { ActivityParamList, RootParamList } from '../../navigation/params';
import ScreenKey from '../../navigation/ScreenKey';
import useCompleteActivity from '../../store/api/hooks/useCompleteActivity';
import { addLoggedOutEvent } from '../../store/loggedOutEvents';
import { selectUserId } from '../../store/session';
import createScreen from '../../utils/createScreen';
import { logger } from '../../utils/logging';
import {
	parsePelvicFloorParams,
	validatePelvicFloorParams,
} from '../../utils/pelvicFloorExercise/isValidPelvicFloorParams';

const ActivityCompleteScreen = () => {
	const completionCalled = useRef(false);
	const userId = useAppSelector(selectUserId);
	const [showSuccessSheet, setShowSuccessSheet] = useState(false);
	const {
		params: { activityId, choices, pelvicFloorExercise },
	} = useRoute<RouteProp<ActivityParamList, ScreenKey.ActivityComplete>>();
	const whoops = useWhoopsHandler();
	const { activityById } = useActivities();
	const activity = activityById(activityId);

	const navigation = useNavigation<NativeStackNavigationProp<RootParamList>>();
	const dispatch = useAppDispatch();
	const redirectToHome = useNavigationRedirect(NavigatorKey.MainNavigator, {
		screen: ScreenKey.Home,
	});
	const navigateToAuthRegister = useCallback(() => {
		navigation.navigate(ScreenKey.Auth, {
			mode: 'register',
			continue: 'true',
			from: 'activity',
		});
	}, [navigation]);

	const choicesObj = useMemo(() => {
		const choicesParams = (choices && JSON.parse(choices)) || {};
		return {
			choicesParams,
			contextVariables: { choices: choicesParams },
		};
	}, [choices]);

	const { variant } = useResolveActivityVariants({
		activity,
		contextVariables: choicesObj.contextVariables,
	});

	const { recordActivityCompleted, isLoading: isCompletingActivity } =
		useCompleteActivity();

	const { canEarnCoins, hasEarnedCoinsToday } =
		useActivityCoinEarningStatus(activityId);

	const getActivityPillar = useActivityPillar();
	const { imageSource: coinImage } = useCoinImage();

	const {
		bottomSheetProps,
		showChallengeProgress,
		isChallengeActivity,
		isChallengeComplete,
	} = useChallengeProgressBottomSheet(activity?.id ?? '');

	const [randomPattern] = useState(() =>
		Math.floor(Math.random() * abstractOverpanelSheetPatterns.length),
	);

	const handleCloseSuccessSheet = () => {
		setShowSuccessSheet(false);
		if (userId) {
			redirectToHome();
		} else {
			navigateToAuthRegister();
		}
	};

	const completeActivity = useCallback(async () => {
		if (!activity || !variant || completionCalled.current) return;

		completionCalled.current = true;

		const parsedParams = parsePelvicFloorParams(whoops, pelvicFloorExercise);
		const { pelvicFloorExerciseParams } = parsedParams || {};

		if (!validatePelvicFloorParams(whoops, pelvicFloorExerciseParams)) {
			navigation.goBack();
			return;
		}

		const context = {
			choices: choicesObj.choicesParams,
			...(pelvicFloorExerciseParams && {
				pelvicFloorExercise: pelvicFloorExerciseParams,
			}),
		};

		const res = await recordActivityCompleted(
			{
				activityId: activity.id,
				activityVariantId: variant?.id,
				context,
				netCoins: !hasEarnedCoinsToday ? activity.netCoins : undefined,
			},
			{
				title: activity.title,
				metadata: activity.metadata,
			},
		);

		const hasSuccessContent = !!variant.successContent;
		if (res && !('error' in res)) {
			if (isChallengeActivity && !isChallengeComplete) {
				showChallengeProgress();
			} else if (hasSuccessContent) {
				setShowSuccessSheet(true);
			} else {
				redirectToHome();
			}
			return;
		}
		// Showing first activity during onboarding / logged out
		else if (!userId && res === undefined) {
			dispatch(
				addLoggedOutEvent({
					action: 'recordActivityCompleted',
					data: {
						payload: {
							activityId: activity.id,
							activityVariantId: variant?.id,
							context,
						},
						analyticsPayload: {
							title: activity.title,
							metadata: activity.metadata,
						},
					},
				}),
			);
			hasSuccessContent ? setShowSuccessSheet(true) : navigateToAuthRegister();
			return;
		}

		if (res?.error) {
			logger.captureError('activity_completed_failed', { error: res.error });
			whoops(
				new Cher(
					res.error.code ? res.error.code : 'activity_completed_failed',
					{
						message: res.error.message,
					},
				),
			);
			navigation.goBack();
		}
	}, [
		activity,
		choicesObj.choicesParams,
		dispatch,
		hasEarnedCoinsToday,
		isChallengeActivity,
		isChallengeComplete,
		navigateToAuthRegister,
		navigation,
		pelvicFloorExercise,
		recordActivityCompleted,
		redirectToHome,
		showChallengeProgress,
		userId,
		variant,
		whoops,
	]);

	useFocusEffect(
		useCallback(() => {
			completeActivity();
		}, [completeActivity]),
	);

	if (!activity) return null;

	if (isCompletingActivity) {
		return <LoadingIndicator label="Complete activity" />;
	}

	const activityPillar = getActivityPillar(activity);
	const canAwardCoins = canEarnCoins && activity.netCoins != null;
	const chipContentVariables = {
		coin_number: canAwardCoins ? (activity.netCoins ?? null) : null,
	};

	const chipContent = (() => {
		if (!variant?.successContent) return;
		if (canAwardCoins && variant.successContent.earnCoinLabel) {
			return variant.successContent.earnCoinLabel.content;
		}
		return variant.successContent.label?.content;
	})();

	const imageSource = canEarnCoins
		? coinImage
		: activityPillar?.rewardImageSource;

	return (
		<>
			{variant?.successContent && (
				<AbstractOverpanelSheet
					visible={showSuccessSheet}
					onClose={handleCloseSuccessSheet}
					title={variant.successContent.title.content}
					ctaTitle={variant.successContent.ctaText}
					content={variant.successContent.content?.content}
					chipContent={chipContent}
					chipContentVariables={chipContentVariables}
					imageSource={imageSource}
					onCtaPress={handleCloseSuccessSheet}
					pattern={abstractOverpanelSheetPatterns[randomPattern]}
				/>
			)}
			{bottomSheetProps && (
				<ChallengeProgressBottomSheet {...bottomSheetProps} />
			)}
		</>
	);
};

export default createScreen(ScreenKey.ActivityComplete, ActivityCompleteScreen);
